import React from 'react';
import styled from 'styled-components';
// components
import { Heading } from '../sections/Home/components/styles';
// layout
import RootLayout from '../layouts/RootLayout';

const Container = styled.section`
    max-width: 1280px;
    padding: 0 20px;
    margin: 100px auto 0;

    @media screen and (max-width: 500px) {
        margin: 50px auto 0;
    }
`;

const SectionHead = styled.div``;
const SectionBody = styled.div`
    margin-top: 32px;
    padding-bottom: 64px;
`;

const SubHead = styled.h3`
    font-size: 18px;
    font-weight: 500;
    margin-top: 16px;
`;

const Ul = styled.ul`
    margin-top: 16px;
    padding-left: 40px;
`;
const Item = styled.li`
    margin-bottom: 16px;
`;

const SubText = styled.p`
    margin-top: 16px;
`;

const Link = styled.a`
    color: #2ca8de;
    display: inline-block;
    margin-top: ${(props) => (props.margin ? '16px' : 'none')};
`;

export default function PrivacyPolicy() {
    return (
        <RootLayout>
            <Container>
                <SectionHead>
                    <Heading secondary center>
                        <span>Authentic</span>ate.com
                    </Heading>
                    <Heading secondary center margin="16px">
                        Privacy Policy
                    </Heading>
                </SectionHead>
                <SectionBody>
                    <SubText>
                        Your privacy and trust are important to us. This Privacy Policy (the
                        “Policy”) provides important information about how Authenticating.com LLC
                        and its worldwide affiliated companies and subsidiaries
                        (“Authenticating.com,” “we,” or “us”) handle your Personal Information. This
                        Policy applies to any Authenticating.com website, application, product,
                        software, or service that links to it (collectively, our “Services”).
                        Occasionally, the Service will link to a different Privacy Policy that will
                        outline the particular privacy practices of that Service.
                    </SubText>
                    <SubText>
                        We offer a wide range of Services to businesses, and individuals, including
                        background screening and identity verification. For example, we offer:
                    </SubText>
                    <Ul>
                        <Item>
                            Technological solutions for providing trust and safety on peer-to-peer
                            exchanges.
                        </Item>
                        <Item>
                            Identity confirmation for individuals to prove themselves online.
                        </Item>
                        <Item>
                            Background checks and identity verification for businesses that want to
                            screen current or prospective users.
                        </Item>
                    </Ul>
                    <SubText>
                        Depending on the Service you use, we may collect different information about
                        you, and additional privacy terms, notices, and/or disclosures may apply to
                        your use of those Services. This Policy does not cover data collected by or
                        through third-party websites or services or any information collected in any
                        way other than through the Services.
                    </SubText>
                    <SubText>
                        IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, YOU ARE NOT GRANTED PERMISSION
                        TO USE THIS WEBSITE OR THE SERVICES AND MUST EXIT IMMEDIATELY
                    </SubText>
                    <SubText>
                        Please read this Policy carefully and contact us if you have any questions
                        about our privacy practices or your Personal Information choices. It is
                        important that you check back often for updates to this Policy. If we
                        materially change this Policy, we will notify you in accordance with
                        applicable laws, by placing a notice on the relevant Services and/or contact
                        you using other methods such as email.
                    </SubText>
                    <SubText>This Policy was last updated on March 3, 2022.</SubText>
                    <SubHead>Personal Information</SubHead>
                    <SubText>
                        Authenticating.com is committed to the responsible handling and protection
                        of your Personal Information.
                    </SubText>
                    <SubText>
                        “Personal Information” means any information relating to an identified or
                        identifiable natural person – i.e., a person who can be identified, directly
                        or indirectly, in particular by reference to an identifier such as a name,
                        an identification number, location data, online identifier or to one or more
                        factors specific to the physical, physiological, genetic, mental, economic,
                        cultural, or social identity of that person.
                    </SubText>
                    <SubText>
                        We collect, use, disclose, transfer, and store Personal Information when
                        needed to provide our Services, for our operational and business purposes,
                        and as otherwise described in this Policy. We want to be clear about our
                        privacy practices so that you can make informed choices about the use of
                        your information, and we encourage you to contact us at any time with
                        questions or concerns.
                    </SubText>
                    <SubHead>The types of Personal Information We Collect:</SubHead>
                    <SubText>
                        We collect Personal Information from you in many ways. For example, if you
                        purchase or use our Services, request information, register for an event, or
                        request customer support, we may ask you to provide information such as your
                        name, address, phone number, government-issued identification number, email
                        address, user name and password, and information about your device. Not all
                        of the Personal Information Authenticating.com holds about you will always
                        come directly from you. It may, for example, come from your employer, other
                        organizations to which you belong, or a professional service provider such
                        as your tax or accounting professional or attorney, or another
                        company/website, if they use our Services. We also collect Personal
                        Information from third parties such as our partners, service providers, and
                        publicly available websites, to offer Services we think may be of interest
                        and to help us maintain data accuracy and provide and enhance the Services.
                    </SubText>
                    <SubText>
                        In addition, our servers, logs, and other technologies automatically collect
                        certain information to help us administer, protect, and improve our
                        Services; analyze usage; and improve users’ experience. We share Personal
                        Information with others only as described in this Policy, or when we believe
                        that the law permits or requires it.
                    </SubText>
                    <SubHead>Services for Business Customers</SubHead>
                    <SubText>
                        We may collect Personal Information from you in order to provide Services to
                        our business customers. For example, if you are the subject of a background
                        check, or if a business customer requests that you verify your identity with
                        us, we may collect some identifying information about you in order to
                        provide those Services.
                    </SubText>
                    <Ul>
                        <Item>
                            Verifying your identity. To run an identity verification check on you
                            for a business customer, we collect Personal Information from you so
                            that we can confirm that you are who you say you are. This Personal
                            Information could include your legal name, date of birth, and other
                            identifying details such as your Social Security number, residential
                            address, phone number, email address, social media handles, photos or a
                            government-issued photo ID document.
                        </Item>
                        <Item>
                            Authorizing a background check. When you authorize one of our business
                            customers to run a background check on you, we may use your legal name,
                            date of birth, and other identifying details such as your Social
                            Security number, residential address, phone number, email address,
                            social media handles, etc. (depending on the type of background check
                            requested).
                        </Item>
                        <Item>
                            We corroborate your legal name, date of birth, and other identifying
                            details such as your Social Security number, residential address, phone
                            number, email address, social media handles, and photos against
                            authoritative databases, such as those maintained by consumer data
                            bureaus, financial institutions, and government agencies.
                        </Item>
                        <Item>
                            We confirm your phone number during the provision of the Services by
                            verifying a unique code that we send you.
                        </Item>
                        <Item>
                            We confirm your email during the provision of the Services by verifying
                            a unique link that we send you.
                        </Item>
                        <Item>
                            We confirm your photos during the provision of the Services by verifying
                            a unique self-portrait taken instantaneously by you.
                        </Item>
                        <Item>
                            We confirm your social media accounts during the provision of the
                            Services by verifying your login information.
                        </Item>
                    </Ul>
                    <SubHead>Collection from Third Party Sources</SubHead>
                    <SubText>
                        If you authorize us to create a background report about you, we'll collect
                        public records and other information from third-party sources to assemble
                        the report. We may also verify information you provide us against
                        authoritative government databases or information providers. If you are a
                        business customer, we may also use data providers like Salesforce, HubSpot,
                        and LinkedIn to supplement the information you provide to us.
                    </SubText>
                    <SubText>
                        Occasionally, we may collect and process what may be considered Sensitive
                        Personal Information. “Sensitive Personal Information” is a subset of
                        Personal Information and is generally defined as any information related to
                        racial/ethnic origin, political opinions, religious beliefs, trade union
                        membership, physical or mental health, other medical information including
                        biometric and genetic data, or sexual life or preferences. In some
                        instances, Sensitive Personal Information may also include criminal
                        allegations or convictions, precise geolocation information, financial and
                        bank account numbers, or unique identifiers such as governmentissued social
                        security numbers, driver’s license, and passport numbers.
                    </SubText>
                    <SubText>
                        For example, if you purchase something from us or subscribe to our Services,
                        we will collect payment information, such as financial or bank card
                        information, and other information necessary for us to process the
                        transaction. If your service professional uses our Services, we will collect
                        and process financial and tax information. Some of our Services will ask you
                        to share your precise geolocation so we can customize your experience and
                        increase the accuracy of the Service. We may also collect information such
                        as a government-issued identification number. Information that is considered
                        sensitive under applicable law will be handled in accordance with such laws.
                    </SubText>
                    <SubHead>Biometric Identity Verification</SubHead>
                    <SubText>
                        Biometric identity verification is performed using facial recognition,
                        namely, by comparing two or more captured images of faces. For example, we
                        may compare the face on a government-issued photo ID against a "selfie" that
                        you capture and send to us. The biometric identifiers used to perform this
                        comparison are derived from the photographs that you submit. After
                        performing the comparison, the biometric identifiers are destroyed within a
                        reasonable time, although the underlying photographic images may be retained
                        indefinitely. We may also reanalyze the photographic images to confirm the
                        results of an initial identity verification, and the resulting re-created
                        biometric identifiers will similarly be destroyed within 24 hours after
                        re-analysis. Because biometric identifiers are derived from these
                        photographic images for the initial purpose of identity verification and
                        fraud prevention, you agree that we may use this biometric information for
                        up to 3 years after you submit it to us.
                    </SubText>
                    <SubHead>You acknowledge and agree that:</SubHead>
                    <Ul>
                        <Item>
                            biometric information is being collected from you when you submit images
                            to us;
                        </Item>
                        <Item>
                            you have been informed in writing of the specific purpose and length of
                            term for which biometric information is being collected, stored, and
                            used; and
                        </Item>
                        <Item>
                            by using the Services, you are providing a release to us in writing that
                            you expressly authorize us to perform these activities
                        </Item>
                    </Ul>
                    <SubText>
                        We may automatically fail your identity verification, or ask you to provide
                        supplemental identity documents if we detect anomalies or suspicious
                        activity during the provision of the Services, even if you correctly pass
                        other portions of the verification process.
                    </SubText>
                    <SubHead>How we use Personal Information:</SubHead>
                    <Ul>
                        <Item>
                            Account setup and administration: We use Personal Information such as
                            your name, email address, phone number, and information about your
                            device to set up and administer your account, provide technical and
                            customer support and training, verify your identity, and send important
                            account, subscription, and Service information.
                        </Item>
                        <Item>
                            Personalization: We use Personal Information to deliver and suggest
                            tailored content and to personalize your experience with our Services.
                            Some of our Services will ask you to share your precise geolocation so
                            we can customize your experience and increase the accuracy of the
                            Service. If you agree to share your precise geolocation with us, you
                            will be able to turn it off at any time by going to the privacy settings
                            on your mobile device or online.
                        </Item>
                        <Item>
                            Marketing and events: We use Personal Information to deliver marketing
                            and event communications to you across various platforms, such as email,
                            telephone, text messaging, direct mail, and online. If we send you a
                            marketing email, it will include instructions on how to opt out of
                            receiving these emails in the future. We also maintain email preference
                            centers for you to manage your information and marketing preferences.
                            Please remember that even if you opt out of receiving marketing emails,
                            we may still send you important Service information related to your
                            accounts and subscriptions.
                        </Item>
                        <Item>
                            Surveys and polls: If you choose to participate in a survey or poll, any
                            Personal Information you provide may be used for marketing or market
                            research purposes.
                        </Item>
                        <Item>
                            Research and development: We use personal information for internal
                            research and development purposes and to improve and test the features
                            and functions of our Services.
                        </Item>
                        <Item>
                            Chat rooms, messaging, and community and event forums: As part of our
                            Service offerings, we may from time to time provide features including
                            chat rooms, messaging services, and community and event forums for
                            collaboration, peer connection, games, and information exchange
                            purposes. Depending upon the Service, the Personal Information you
                            choose to post, share, upload, or make available is public and visible
                            to others who use those Services. You should never post or share any
                            information that is confidential or about others unless you have
                            permission to do so. We may use information you provide in community and
                            event profiles and forums to personalize your experience and to make
                            content and peer connection recommendations. These Services may have
                            their own Terms of Use and, where appropriate, their own privacy
                            statements. We moderate these Services for compliance with our Terms of
                            Use.
                        </Item>
                        <Item>
                            Hosted services: Some of our Services provide data and document storage
                            as an integral part of the product or solution offering. Documents and
                            data stored by our customers may contain Personal Information in
                            business and personal tax forms, payroll and financial data, for
                            example. Any information stored by or on behalf of our customers is
                            controlled and managed by and only made accessible to those customers or
                            others our customers may authorize from time to time. Our access to this
                            information is limited to Authenticating.com personnel with a critical
                            business reason, such as technical support.
                        </Item>
                        <Item>
                            Legal obligations: We may be required to use and retain Personal
                            Information for legal and compliance reasons, such as the prevention,
                            detection, or investigation of a crime; loss prevention; or fraud. We
                            may also use Personal Information to meet our internal and external
                            audit requirements, information security purposes, and as we otherwise
                            believe to be necessary or appropriate: (a) under applicable law, which
                            may include laws outside your country of residence; (b) to respond to
                            requests from courts, law enforcement agencies, regulatory agencies, and
                            other public and government authorities, which may include such
                            authorities outside your country of residence; (c) to enforce our terms
                            and conditions; and (d) to protect our rights, privacy, safety, or
                            property, or those of other persons.
                        </Item>
                    </Ul>
                    <SubText>
                        We process personal information for these Service- and business-related
                        purposes:
                    </SubText>
                    <SubHead>When we share Personal Information:</SubHead>
                    <SubText>
                        Authenticating.com shares or discloses Personal Information when necessary
                        to provide Services or conduct our business operations as described below.
                        When we share Personal Information, we do so in accordance with data privacy
                        and security laws. We may occasionally share non-personal, anonymized, and
                        statistical data with third parties. Below are the parties with whom we may
                        share your Personal Information and why.
                    </SubText>
                    <Ul>
                        <Item>
                            Within Authenticating.com: Our business is supported by a variety of
                            Authenticating.com teams and functions in various jurisdictions, and
                            Personal Information will be made available to them if necessary for the
                            provision of Services, account administration, sales and marketing,
                            customer and technical support, and business and product development,
                            for instance. All of our employees and contractors are required to
                            follow our data privacy and security policies when handling Personal
                            Information.
                        </Item>
                        <Item>
                            Our business partners: We may occasionally partner with other
                            organizations to deliver co-branded or private-label Services, provide
                            content, or to host content, and events. As part of these arrangements,
                            you may be a customer of both Authenticating.com and our partners, and
                            we and our partners may collect and share information about you.
                            Authenticating.com will handle Personal Information in accordance with
                            this Policy, and we encourage you to review the privacy policies of our
                            partners to learn more about how they collect, use, and share Personal
                            Information.
                        </Item>
                        <Item>
                            Our third-party service providers: We partner with and are supported by
                            service providers around the world. Personal Information will be made
                            available to these parties only when necessary to fulfill the services
                            they provide to us, such as software, system, and platform support;
                            direct marketing services; cloud hosting services; advertising; data
                            analytics; and order fulfillment and delivery. Our third-party service
                            providers are not permitted to share or use Personal Information we make
                            available to them for any other purpose than to provide services to us.
                        </Item>
                        <Item>
                            Data Analysis Partners: We may partner with unaffiliated third parties
                            (such as data brokers, marketing companies, and IT service providers) to
                            provide data integrity analysis. We may share certain demographic
                            information, location data, advertising and device identifiers, IP
                            address, and usage statistics with these partners and other third
                            parties, some of whom may use it to offer promotions to you for other
                            products and services.
                        </Item>
                        <Item>
                            Third parties for legal reasons: We will share Personal Information when
                            we believe it is required, such as:
                            <Ul>
                                <Item>
                                    To comply with legal obligations and respond to requests from
                                    government agencies, including law enforcement and other public
                                    authorities, which may include such authorities outside your
                                    country of residence.
                                </Item>
                                <Item>
                                    In the event of a merger, sale, restructure, acquisition, joint
                                    venture, assignment, transfer, or other disposition of all or
                                    any portion of our business, assets, or stock (including in
                                    connection with any bankruptcy or similar proceedings)
                                </Item>
                                <Item>To protect our rights, users, systems, and Services.</Item>
                            </Ul>
                        </Item>
                    </Ul>
                    <SubText>
                        You acknowledge that, due to the nature of the Services, we will need to
                        share your Personal Information with third parties for the purpose of
                        confirming its authenticity. Those third parties may include consumer data
                        bureaus, government agencies, financial institutions, mobile network
                        operators, and other vendors that provide access into these systems. If a
                        third-party business partner (someone who requires you to provide an
                        identity before accessing their services) has directed you to
                        Authenticating.com to verify your information, you agree that we may share
                        verification results with that party.
                    </SubText>
                    <SubHead>Where we store and process Personal Information.</SubHead>
                    <SubText>
                        The Services are controlled and operated from within the United States. We
                        make no representation that the Services are appropriate or available for
                        use outside the United States, or that access to and use of the Services in
                        accordance with this Policy complies with the laws of your jurisdiction.
                        Those who choose to access the Platform from outside the United States do so
                        on their own will and are fully responsible for compliance with applicable
                        laws.
                    </SubText>
                    <SubHead>How we secure Personal Information.</SubHead>
                    <SubText>
                        Authenticating.com takes data security seriously, and we use various
                        technologies and procedures to protect your Personal Information. Our
                        information security policies and procedures are closely aligned with widely
                        accepted standards and are reviewed regularly and updated to meet our
                        business needs, changes in technology, and regulatory requirements.
                    </SubText>
                    <SubHead>For example:</SubHead>
                    <Ul>
                        <Item>
                            Policies and procedures
                            <Ul>
                                <Item>
                                    We have measures in place to protect against accidental loss and
                                    unauthorized access, use, destruction, or disclosure of data
                                </Item>
                                <Item>
                                    We have a Business Continuity and Disaster Recovery strategy
                                    that is designed to safeguard the continuity of our service to
                                    our clients and to protect our people and assets
                                </Item>
                                <Item>
                                    We place appropriate restrictions on access to Personal
                                    Information
                                </Item>
                                <Item>
                                    We implement security measures and controls, including
                                    monitoring and physical measures, to store and transfer data
                                    securely
                                </Item>
                                <Item>
                                    We conduct Privacy Impact Assessments in accordance with legal
                                    requirements and our business policies
                                </Item>
                            </Ul>
                        </Item>
                        <Item>
                            Training for employees and contractors
                            <Ul>
                                <Item>
                                    We require privacy, information security, and other applicable
                                    training on a regular basis for our employees and contractors
                                    who have access to Personal Information and other sensitive data
                                </Item>
                                <Item>
                                    We take steps to ensure that our employees and contractors
                                    operate in accordance with our information security policies and
                                    procedures and any applicable contractual conditions
                                </Item>
                            </Ul>
                        </Item>
                        <Item>
                            Vendor risk management
                            <Ul>
                                <Item>
                                    We require, through the use of contracts and security reviews,
                                    our third-party vendors and providers to protect any Personal
                                    Information with which they are entrusted in accordance with our
                                    security policies and procedures
                                </Item>
                            </Ul>
                        </Item>
                    </Ul>
                    <SubHead>How long we keep Personal Information.</SubHead>
                    <SubText>
                        Authenticating.com implements policies and rules relating to the retention
                        of Personal Information. We retain Personal Information for as long as we
                        reasonably require it for legal or business purposes. In determining data
                        retention periods, Authenticating.com takes into consideration local laws,
                        contractual obligations, and the expectations and requirements of our
                        customers. When we no longer need Personal Information, we securely delete
                        or destroy it.
                    </SubText>
                    <SubHead>Your right to access and correct your Personal Information.</SubHead>
                    <SubText>
                        We respect your right to access and control your information, and we will
                        respond to requests for information and, where applicable, will correct,
                        amend, or delete your Personal Information.
                    </SubText>
                    <Ul>
                        <Item>
                            Access to Personal Information: If you request access to your Personal
                            Information, we will gladly comply, subject to any relevant legal
                            requirements and exemptions, including identity verification procedures.
                            Before providing data to you, we will ask for proof of identity and
                            sufficient information about your interaction with us so that we can
                            locate any relevant data. We may also charge you a fee for providing you
                            with a copy of your data (except where this is not permissible under
                            local law).
                        </Item>
                        <Item>
                            Correction and deletion: In some jurisdictions, you have the right to
                            correct or amend your Personal Information if it is inaccurate or
                            requires updating. You may also have the right to request deletion of
                            your Personal Information; however, this is not always possible due to
                            legal requirements and other obligations and factors. Remember that you
                            can update your account information by contacting us.
                        </Item>
                        <Item>
                            Marketing preferences: To opt out of email marketing, you can use the
                            unsubscribe link found in the email communication you receive from us or
                            visit the applicable email preference center. For other marketing
                            preferences, you can use the “Contact Us” option within the relevant
                            Service. Information related to controlling cookies can be found herein,
                            as can information related to Interest-Based and Mobile Advertising.
                        </Item>
                        <Item>
                            Filing a complaint: If you are not satisfied with how Authenticating.com
                            manages your personal data, you may have the right to make a complaint
                            to a data protection regulator in your jurisdiction.
                        </Item>
                        <Item>
                            California Residents: California Civil Code Section 1798.83 (also known
                            as the “Shine The Light” law) permits users who are California residents
                            to request and obtain from us once a year, free of charge, information
                            about the Personal Information (if any) that we disclosed to third
                            parties for direct marketing purposes in the preceding calendar year. If
                            applicable, this information would include a list of the categories of
                            Personal Information that was shared and the names and addresses of all
                            third parties with which we shared information in the immediately
                            preceding calendar year. If you are a California resident and would like
                            to make such a request, please submit your request to us at the
                            addresses listed below.
                        </Item>
                    </Ul>
                    <SubText>
                        Please contact us with any requests related to your Personal Information.
                    </SubText>
                    <SubHead>Cookies and similar technologies</SubHead>
                    <SubText>
                        Authenticating.com and our third-party providers set and use cookies and
                        similar technologies to store and manage user preferences, deliver targeted
                        advertising, enable content, and gather analytic and usage data, for
                        example. The use of cookies and other tracking technologies is standard
                        across websites and apps through which information is collected about your
                        online activities across applications, websites, or other services. More
                        information about how we use cookies and similar technologies is below.
                    </SubText>
                    <SubHead>What is a cookie?</SubHead>
                    <SubText>
                        A cookie is a small text file that is placed on a computer or other device
                        and is used to identify the user or device and to collect information.
                        Cookies are typically assigned to one of four categories, depending on their
                        function and intended purpose: absolutely necessary cookies, performance
                        cookies, functional cookies, and cookies for marketing purposes.
                    </SubText>
                    <SubHead>Types of cookies and why we use them.</SubHead>
                    <Ul>
                        <Item>
                            Absolutely necessary cookies: These cookies are essential to enable you
                            to move around a website and use its features. Without these cookies,
                            services you have asked for, like adding items to an online shopping
                            cart, cannot be provided.
                        </Item>
                        <Item>
                            Performance cookies: These cookies collect information about how you use
                            our websites. Information collected includes, for example, the Internet
                            browsers and operating systems used, the domain name of the website
                            previously visited, the number of visits, average duration of visit, and
                            pages viewed. These cookies don’t collect information that personally
                            identifies you and only collect aggregated and anonymous information.
                            Performance cookies are used to improve the user-friendliness of a
                            website and enhance your experience.
                        </Item>
                        <Item>
                            Functionality cookies: These cookies allow the website to remember
                            choices you make (such as your username or ID, language preference, or
                            the area or region you are in) and provide enhanced, more personal
                            features. These cookies can also be used to remember changes you have
                            made to text size, fonts, and other customizable parts of web pages.
                            They may also be used to provide services you have asked for, such as
                            watching a video or commenting on a blog. The information these cookies
                            collect may be anonymized, and they cannot track your browsing activity
                            on other websites.
                        </Item>
                        <Item>
                            Targeting and advertising cookies: These cookies track browsing habits
                            and are used to deliver targeted (interest-based) advertising. They are
                            also used to limit the number of times you see an ad and to measure the
                            effectiveness of advertising campaigns. They are usually placed by
                            advertising networks with the website operator’s permission. They
                            remember that you have visited a website and this information is shared
                            with other organizations, such as advertisers.
                        </Item>
                    </Ul>
                    <SubHead>Managing cookies.</SubHead>
                    <SubText>
                        You can manage website cookies in your browser settings, and you always have
                        the choice to change these settings by accepting, rejecting, or deleting
                        cookies. If you choose to change your settings, you may find that certain
                        functions and features will not work as intended. All browser settings are
                        slightly different, so to manage cookies, you should refer to the relevant
                        settings within your browser.
                    </SubText>

                    <SubHead>Other tracking technologies.</SubHead>
                    <Ul>
                        <Item>
                            Local shared objects/Flash cookies: Flash cookies, also known as local
                            shared objects, are designed to support browser content supported by
                            Adobe® Flash. They are usually used to enable ads and video content on
                            websites. Like other cookies, they will store information on your
                            device, some of which will be specific to the Flash-enabled content.
                            Flash cookies can only be deleted within Adobe Flash rather than via
                            your browser.
                        </Item>
                        <Item>
                            Web beacons: Our web pages may contain electronic images known as web
                            beacons (also called single-pixel gifs and transparent graphic images)
                            that we use to help deliver cookies on our sites, count users who have
                            visited those sites, deliver services, and analyze the effectiveness of
                            our promotional campaigns, for example. We may also include web beacons
                            in our marketing email messages or newsletters to determine whether an
                            email is opened or if links are clicked.
                        </Item>
                        <Item>
                            Web server & application logs: Our servers automatically collect certain
                            information to help us administer and protect the Services, analyze
                            usage, and improve users’ experience. The information collected
                            includes:
                            <Ul>
                                <Item>IP address and browser type</Item>
                                <Item>
                                    Device information including Unique Device Identifier (UDID),
                                    MAC address, Identifier For Advertisers (IFA), and similar
                                    identifiers we or others may assign
                                </Item>
                                <Item>Device operating system and other technical facts</Item>
                                <Item>
                                    The city, state, and country from which you access our website
                                </Item>
                                <Item>Pages visited and content viewed, stored, and purchased</Item>
                                <Item>Information or text entered</Item>
                                <Item>URLs visited before and after you use our Services</Item>
                            </Ul>
                        </Item>
                    </Ul>
                    <SubHead>Interest-based advertising (IBA)</SubHead>
                    <SubText>
                        Interest-based advertising (IBA) allows us to deliver targeted advertising
                        to users of our Services. IBA works by showing you advertisements that are
                        based on the type of content you access or read. For example, as you browse
                        our Services, one of the cookies placed on your device may be an advertising
                        cookie so we can better understand what sort of pages or content you are
                        interested in. The information collected about your device enables us to
                        group you with other devices that have shown similar interests. We can then
                        display advertising to categories of users that is based on common
                        interests.
                    </SubText>
                    <SubHead>Opting out of IBA.</SubHead>

                    <SubText>
                        If you want to opt out of receiving interest-based advertising, it does not
                        mean that you will no longer receive advertising when you are using our
                        Services. It just means that we will not use information collected about you
                        for IBA and that any advertising you see will not be customized or relevant
                        to you. You can exercise your online advertising choices at{' '}
                        <Link href="https://optout.aboutads.info/?c=2&lang=EN">
                            {' '}
                            http://optout.aboutads.info{' '}
                        </Link>{' '}
                        or by clicking the AdChoices icon in an ad and following the instructions.
                        You may also opt out of receiving interest-based ads from many sites through
                        the Network Advertising Initiative's (NAI) Opt Out Tool
                        (http://www.networkadvertising.org/choices). If you delete cookies, use a
                        different device, or change web browsers, you may need to opt out again.
                    </SubText>
                    <SubHead>Advertising on mobile devices.</SubHead>
                    <SubText>
                        Mobile devices have an identifier that gives companies the ability to serve
                        targeted ads to a specific mobile device. In many cases, you can turn off
                        mobile device ad tracking or you can reset the advertising identifier at any
                        time within your mobile device privacy settings. Another tool you can use to
                        control advertising on your mobile device is the AppChoices App:{' '}
                        <Link href="https://youradchoices.com/appchoices">
                            {' '}
                            http://youradchoices.com/appchoices
                        </Link>
                        . You may also choose to turn off location tracking on your mobile device.
                        If you turn off ad tracking or location tracking, we will no longer use
                        information collected from your device’s advertising identifier for the
                        purposes of advertising. You may still see the same number of ads but they
                        may be less relevant because they will not be based on your interests.
                    </SubText>
                    <SubHead>Do not track</SubHead>
                    <SubText>
                        Some browsers transmit Do Not Track (DNT) signals to websites. Due to the
                        lack of a common interpretation of DNT signals throughout the industry,
                        Authenticating.com does not currently alter, change, or respond to DNT
                        requests or signals from these browsers. We will continue to monitor
                        industry activity in this area and reassess our DNT practices as necessary.
                    </SubText>
                    <SubHead>Connecting via social networks</SubHead>
                    <SubText>
                        Some of the Services may include social networking features, such as the
                        Facebook® “Like” button and widgets, “Share” buttons, and interactive
                        miniprograms. Additionally, you may choose to use your own social networking
                        logins from, for example, Facebook or LinkedIn®, to log into some of our
                        Services. If you choose to connect using a social networking or similar
                        service, we may receive and store authentication information from that
                        service to enable you to log in and other information that you may choose to
                        share when you connect with these services. These services may collect
                        information such as the web pages you visited and IP addresses, and may set
                        cookies to enable features to function properly. We are not responsible for
                        the security or privacy of any information collected by these third parties.
                        You should review the privacy statements or policies applicable to the
                        third-party services you connect to, use, or access. If you do not want your
                        Personal Information shared with your social media account provider or other
                        users of the social media service, please do not connect your social media
                        account with your account for the Services and do not participate in social
                        sharing on the Services.
                    </SubText>
                    <SubHead>Links and connections to third-party services</SubHead>
                    <SubText>
                        Our Services may contain links to and may be used by you in conjunction with
                        third-party apps, services, tools, and websites that are not affiliated
                        with, controlled, or managed by us. Examples include Facebook, LinkedIn,
                        Twitter® and other third-party apps like voice software and readers. The
                        privacy practices of these third parties will be governed by the parties’
                        own Privacy Policies. We are not responsible for the security or privacy of
                        any information collected by these third parties. You should review the
                        privacy statements or policies applicable to these third-party services.
                    </SubText>
                    <SubHead>Children’s privacy</SubHead>
                    <SubText>
                        Authenticating.com provides information solutions for professionals, and our
                        Services are generally not aimed at children. If, however, we collect and
                        use information about children, such as to develop an educational resource,
                        we will comply with industry guidelines and applicable laws.
                    </SubText>
                    <SubHead>How to contact us</SubHead>
                    <SubText>
                        We understand that you may have questions or concerns about this Policy or
                        our privacy practices or may wish to file a complaint. Please feel free to
                        contact us in one of the following ways:
                    </SubText>
                    <SubText>Authenticating.com Data Protection Officer</SubText>
                    <Link margin href="mailto:info@authenticating.com">
                        Email: info@authenticating.com
                    </Link>
                    <SubHead>Address</SubHead>
                    <SubText>
                        730 Arizona Avenue, 230 <br /> Santa Monica, CA 90401 <br /> Attention: Data
                        Protection Officer
                    </SubText>
                    <SubHead>Marketing preferences and services support</SubHead>
                    <SubText>
                        To update your account information or email marketing preferences, or for
                        technical support or other help with your Services, please visit the
                        applicable email preference center or use the{' '}
                        <Link href="/#contact">Contact Us</Link> option for the relevant Service.
                    </SubText>
                </SectionBody>
            </Container>
        </RootLayout>
    );
}
