import styled from "styled-components";
import theme from "../../../themes/theme";

export const Heading = styled.h1`
  font-family: ${theme.fonts.main};
  font-weight: 500;
  font-size: ${(props) =>
    props.secondary ? theme.fontsize.subHeading : theme.fontsize.heading};
  color: #212121;

  text-align: ${(props) => (props.center ? "center" : "none")};

  span {
    color: ${theme.colors.primary};
  }
  margin-top: ${({ margin }) => margin || "none"};

  @media screen and (max-width: 500px) {
    font-size: ${(props) => (props.secondary ? "24px" : "54px")};
    padding: ${(props) => (props.padding ? props.padding : "10px")};
  }
`;

export const SubHeading = styled.p`
  font-family: ${theme.fonts.main};
  font-size: ${(props) => (props.subHeading ? "18px" : "16px")};
  font-weight: 400;
  color: #212121;
  margin-top: ${({ margin }) => margin || "none"};
  span {
    color: #0066cc;
  }

  @media screen and (max-width: 500px) {
    font-size: ${(props) => (props.subHeading ? "14px" : "16px")};
  }
`;

export const SubText = styled.p`
  font-family: ${theme.fonts.main};
  font-size: ${(props) => (props.small ? "14px" : "16px")};
  text-align: center;
  font-weight: 400;
  // color: #212121;
  color: white;
  margin-top: ${({ margin }) => margin || "none"};
`;

export const AuthSvg = styled.span`
  display: inline-flex;
  align-self: center;
  margin-bottom: -12.5%;
  color: #212121;

  svg {
    height: 0.8em;
    width: auto;
  }
`;
